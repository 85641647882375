import React from "react";

export default function Footer() {
  return (
    <React.Fragment>
      {" "}
      <footer className="footer">
        <div className="container">
          <a
            target="_blank"
            rel="noreferrer noopenner"
            href="https://rafinad.io/download-file/pravila-konkursa-mts"
          >
            <p className="rules__header">
              Правила конкурса «Оформите кредитную карту MTS CASHBACK от МТС
              Банка и получите в подарок сертификат Ozon номиналом 2000 рублей»
            </p>
          </a>
          <div className="rules__header-text">
            Конкурс «Оформите кредитную карту MTS CASHBACK от МТС Банка и получите в подарок сертификат Ozon номиналом
            2000 рублей» действует с 21.06.2024 по 30.09.2024. Организатор акции ООО "РАФИНАД". Участники: физ. лица
            (18+), граждане и резиденты РФ, у которых нет кредитных карт MTS CASHBACK от МТС Банка, а также заявок на
            них в течение 90 дней на момент оформления кредитной карты MTS CASHBACK; Сертификат 2000 руб. на Озон
            отправляет партнёр ООО "РАФИНАД" в течение 60 дней, при условии, что вы оставили номер телефона на лендинге
            https://rafinad-kkmt.ru, оформили одну кредитную карту MTS CASHBACK от МТС Банка и совершили по ней
            первую покупку от 500 руб. в течение 30 дней. ПАО «МТС-Банк» Лицензия Банка России №2268 от 17.12.2014 г.
            Информация актуальна на 22.05.2024. Реклама. По всем вопросам касательно отправки сертификата Ozon
            обращайтесь на{" "}
            <a href="mailto:sertificate@rafinad.io">sertificate@rafinad.io</a>
          </div>
          <div className="rules__header-text">
            С 1 января 2024 года по 31 января 2025 года (включительно) проводится акция «Приведи друга». Максимальный
            размер кешбэка 1500 рублей при совершении покупок с помощью карты (оформленной в рамках настоящей акции) в
            торгово-сервисных предприятиях на сумму более 3000 рублей (есть категории исключения). Информация об
            организаторе акции, о правилах ее проведения, количестве призов по результатам ее проведения, сроках, месте
            и порядке их получения, размещены по{" "}
            <a
              target="_blank"
              rel="noreferrer noopenner"
              href="https://www.mtsbank.ru/upload/static/documents/portal-frontend-cards/poryadok-po-akcii-privedi-druga.pdf"
            >
              ссылке
            </a>. Реклама ПАО «МТС БАНК». Ген. Лицензия ЦБ РФ №2268 от 17.12.2014 г.
          </div>
          <div className="rules__header-text">
            Условия использования карты ТП 84 МТS CASHBACK: согласно тарифу 83-84, диапазон значения полной стоимости
            потребительского кредита - от 11,632% до 66,352% годовых. Льготный период – это период, когда вы не платите
            проценты за операции покупок (выполняете целевое использование кредитных средств – операции в ТСП). Льготный
            период кредитования не распространяется на операции получения наличных денежных средств и безналичные
            переводы физическим лицам за счёт Кредита. Льготный период отсчитывается от первого числа каждого месяца и
            длится 111 дней. Проценты за пользование кредитом, при соблюдении условий льготного периода, не начисляются.
            При нарушении условий льготного периода – процентная ставка от 11,9% до 49,9% годовых, нарушением льготного
            периода будет считаться: не внесение минимального платежа и/или не выплата долга в срок. Процентная ставка
            на снятие наличных и переводы вне беспроцентного периода - 69,9% годовых. Имеется комиссии за получение
            наличных денежных средств и переводы. Реклама. ПАО «МТС БАНК». Ген. Лицензия ЦБ РФ №2268 от 17.12.2014 г.
            Подробнее – на mtsbank.ru
          </div>
          <a
            target="_blank"
            rel="noreferrer noopenner"
            href="https://rafinad.io/download-file/poryadok-provedeniya-akcii-privedi-druga"
          >
            <p className="rules__header-bottom">
              ПОРЯДОК проведения стимулирующей акции «Приведи друга»
            </p>
          </a>
        </div>
      </footer>
    </React.Fragment>
  );
}
